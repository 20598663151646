h2.HomeTeam2 {
    /* background-color: #E1E4EB; */
    /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
    padding: 1px;
    color:rgba(237, 237, 237, 0.793);
    filter: drop-shadow(1px 1px 19px #edf2fa);
}

@media screen and (min-width: 901px) {
    h2.HomeTeam2 {
        font-size: 38px;
    }
}

@media screen and (max-width: 950px) {
    h2.HomeTeam2 {
        font-size: 23px;
    }
}

.FondoVTeam{
    z-index: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    object-fit: cover;
    left: 0;
    opacity: 0.8888888;
    height: 100%;
    width: 99%.5;
    border-bottom: 80px double rgb(43, 58, 64);
    /* width: 100%; */

    /* backdrop-filter: blur(10px); */
    /* bottom: 0; */
    /* top: 0; */
    /* position: fixed;
    top: 0;
    right: 0; */

}

.imaTeam821{
    filter: drop-shadow(9px 8px 10px #0c0b0b);
    border-radius: 10px 10px 20px 20px;
}
.container-fluid5{
    width: 77%;
}