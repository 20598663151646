.SombraSNT {
  filter: drop-shadow(0px 0px 3px blue);
}

.parraRELE {
  font-family: 'EB Garamond', serif;
  color: #202721;
  font-size: 16pt;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

/* .PruebaTa { */
  /* font-size: calc(1em + 1vw); */
  /* font-size: 1.7em; */
/* } */

/* Letter2 */
h2.PruebaTa {
  /* background-color: #E1E4EB; */
  filter: drop-shadow(9px 8px 10px #0c0b0b);
  padding: 1px;
}

@media screen and (min-width: 1650px) {
  h2.PruebaTa {
    font-size: 30px;
  }
}

@media screen and (max-width: 1650px) {
  h2.PruebaTa {
    font-size: 30px;
  }
}
@media screen and (max-width: 1500px) {
  h2.PruebaTa {
    font-size: 28px;
  }
}
@media screen and (max-width: 1350px) {
  h2.PruebaTa {
    font-size: 26px;
    /* font-size: 2vw; */
  }
}
@media screen and (max-width: 1200px) {
  h2.PruebaTa {
    font-size: 22px;
  }
}
@media screen and (max-width: 1050px) {
  h2.PruebaTa {
    font-size: 18px;
  }
}
@media screen and (max-width: 900px) {
  h2.PruebaTa {
    font-size: 14px;
  }
}

/* Scroll */

.ScrollRelevantes {
  overflow-y: scroll;
  height: 568px;
}
.ScrollRelevantes::-webkit-scrollbar {
  width: 7px;
}
.ScrollRelevantes::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1C1853;
  border-radius: 20px;
}
.ScrollRelevantes::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.ScrollRelevantes::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}