.flotante {
    position: absolute;
    bottom:200px;
    right:50px;
}
.flotante2 {
    position: absolute;
    bottom:200px;
    right:15px;
}

.flotin1 {
    position: absolute;
    bottom: 200px;
    right:50px;
}
.flotin2 {
    position: absolute;
    bottom:200px;
    right:15px;
}

.flotin3 {
    position: absolute;
    bottom: -12%;
    right: 10%;
}
.flotin4 {
    position: absolute;
    bottom: -5%;
    right: 10%;
}
.flotin5 {
    position: absolute;
    bottom: -4%;
    right: 10%;
}
.flotin6 {
    position: absolute;
    bottom: -10%;
    right: 10%;
}
.flotin7 {
    position: absolute;
    bottom: -25%;
    right: 10%;
}
.INIP{
    position: absolute;
    bottom: -20px;
    right: 0px;
}

