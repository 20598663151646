.ACD1{
    width: 15rem;
    align-content: center;
    align-items: center;
    display: inline-block;
}
.BrilloSNT{
    border-radius:6px;    
    filter: drop-shadow(1px 1px 5px rgb(70, 5, 13));
}

.BrilloSNT2{
    color: blue;
    font-size: 16pt;
    filter: drop-shadow(1px 2px 1px rgb(224, 119, 119));
}
.SomSAE{
    filter: drop-shadow(1px 1px 5px rgb(70, 5, 13));
}
.SomCOI{
    filter: drop-shadow(1px 1px 5px rgb(17, 50, 104));
}
.SomNOI{
    filter: drop-shadow(1px 1px 5px rgb(17, 50, 104));
}
.PruebaSNT4{
    /* SAE */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#f1a3a3, #fff1f3);
    border-radius: 20px;
}
.sntS{
    background: linear-gradient(#fff1f3, #fff1f3,#fff1f3);
}
.PruebaSNT3{
    /* COI */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#94e0f0, #effbfd);
    /* background-color: #effbfd; */
    border-radius: 10px;
}
.sntC{
    background: linear-gradient(#effbfd, #effbfd,#fff8f3);
}
.PruebaSNT2{
    /* NOi */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#e7c3a3, #fff8f3);
    /* background-color: #fdf6f0; */
    border-radius: 10px;
}
.sntN{
    background: linear-gradient(#fdf6f0, #fdf6f0,#fcfff2);
}
.PruebaSNT{
    /* Banco */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#dfee9c, #fcfff2);
    /* background-color: #fafcf2; */
    border-radius: 10px;
}
.sntB{
    background: linear-gradient(#fafcf2, #fafcf2,#f6f4ff);
}
.PruebaSNT5{
    /* Prod */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#bbabfc, #f6f4ff);
    /* background-color: #f7f5ff; */
    border-radius: 10px;
}
.sntP{
    background: linear-gradient(#f7f5ff, #f7f5ff,#fffcee);
}
.PruebaSNT6{
    /* Caja */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#e4d38a, #fffcee);
    /* background-color: #fffcee; */
    border-radius: 10px;
}
.sntCA{
    background: linear-gradient(#fffcee, #fffcee,#fff9fa);
}
.PruebaSNT7{
    /* Facture */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#f0a2af, #fff9fa);
    /* background-color: #fff9fa; */
    border-radius: 10px;
}
.sntFA{
    background: linear-gradient(#fff9fa, #fff9fa,#f7f7ff);
}
.AH2{
    color:#808188;
}
.Tit1{
    color:#EC0928;
    margin-left: 3.5%;
}
.Tit2{
    color:#00ADD9;
    margin-right: 8%;
}
.AH3{
    color: #262727;
}
.SombraSNT{
    filter: drop-shadow(0px 0px 3px blue);
}
.hrSNT {
    width: 80%;
    border-top: 1.6px dashed white!important;
    filter: drop-shadow(5px 5px 5px blue);
}

/* Scroll Areas de interes Sistemas Aspel */
.PNomina {
    overflow-y: scroll;
    height: 126px;
}
.PNomina::-webkit-scrollbar {
    width: 5px;
  }
  .PNomina::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
    border-radius: 20px;
  }
  .PNomina::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .PNomina::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }

  .CGastos {
    overflow-y: scroll;
    height: 126px;
}
.CGastos::-webkit-scrollbar {
    width: 5px;
  }
  .CGastos::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
    border-radius: 20px;
  }
  .CGastos::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .CGastos::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }

  .LinearSistemas{
    background: linear-gradient(0deg, rgba(2, 0, 36, 0.767) 0%, rgb(255, 255, 255)5%,rgba(100, 151, 218, 0.5) 100%);
  }
  .LinearSistemas2{
    background: linear-gradient(0deg, rgba(2, 0, 36, 0.767) 0%, rgb(255, 255, 255)5%,rgba(100, 155, 218, 0.856) 100% ,rgba(61, 131, 223, 0.884) 40%);
    height: 488px;
  }
  .scrollSA {
    overflow-y: scroll;
    height: 120px;
}
.scrollSA::-webkit-scrollbar {
    width: 5px;
  }
  .scrollSA::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
    border-radius: 20px;
  }
  .scrollSA::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .scrollSA::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }
  .FoSA{
    background-color:#00006915;
  }