.TamSNT{
    width: 100%;
    margin-left: 2.4%;
    max-width: 95%;
    /* background-color: black; */
}
.FIzquierda1{
    margin-right: 90%;
}
.FDerecha1{
    margin-left: 90%;
}
.SombraIma{
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    border-radius: 5px 5px 5px 5px;
}
.TipoLetraSNT{
    font: oblique bold 200% cursive;
    color: rgb(30, 30, 56);
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    
}
.Tama2{
    font-size: 14pt;
    color: #696A6F;
}
.Tama23{
    font-size: 14pt;
    color: rgba(102, 32, 207, 0.271);
}
p.Tama23 {
    /* background-color: #E1E4EB; */
    filter: drop-shadow(9px 8px 10px #0c0b0b);
    padding: 1px;
  }
  
  @media screen and (min-width: 901px) {
    p.Tama23 {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 1050px) {
    p.Tama23 {
      font-size: 12px;    
    }
  }

  @media screen and (max-width: 1100px) {
    p.Tama23 {
      font-size: 9px;    
    }
  }