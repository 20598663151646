@import url(https://fonts.googleapis.com/css?family=Merriweather);

html,
body {
    /* background: #f1f1f1; */
    font-family: 'Merriweather', sans-serif;
    /* padding: 1em; */
}

h1 {
    text-align: center;
    color: #a8a8a8;
}

form {
    max-width: 600px;
    text-align: center;
    margin: 20px auto;
}

input,
textarea {
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    font-family: 'Merriweather', sans-serif;
    resize: none;
    color:rgb(0, 0, 0);
}

#input-submit {
    color: rgb(255, 255, 255);
    font-size: 12pt;
    background: #4F81C5;
    cursor: pointer;
}

#input-submit:hover {
    box-shadow: 0 2px 2px 2px #07218771;
    filter: drop-shadow(4px 10px 6px #556888);
}

textarea {
    height: 258px;
}
.inputForm2{
    /* background-color: #dae9fd93; */
    background-color: #ecf0f7d5;
}
.CardFormulario{
    background:url(../../IMG/FORMULARIO_C/Heroimagepartners.jpg);
    background-repeat: none;
    background-size:cover;
    /* background-color: #AEB6A7; */
    /* background: rgb(131, 30, 30); */

}

.AceptoAviso{
    opacity: 0.896;
    box-shadow: 0 2px 2px 2px #07218771;
    filter: drop-shadow(4px 10px 6px #f2f3f5);
    background: linear-gradient(to left, transparent,#ebebf0);

}
.CardFormulario23{
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0pt;
    padding-right: 0pt;
    margin-bottom: 0;
    background:url("../../IMG/Jumbotron/Barra.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.Nivel1P1{
    width: 100%;
    height: 493px;
}
.vidFormula821{
    position: absolute;
    left: 0;
    /* top: 0; */
    width: 100%;
    height: 493px;
    /* z-index: 10; */
    opacity: 0.4;
    visibility: visible;
    background: linear-gradient(to right, transparent,#00012D);
}
.CardFormulario18{
    background: linear-gradient(270deg, rgba(2,0,36,1) 0%,  rgb(255, 255, 255)5%,rgba(23, 113, 230, 0.5) 100%);
}
.half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
}

.right {
    width: 50%;
}

.left {
    margin-right: 2%;
}

@media (max-width: 480px) {
    .half {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
}

.cf:before,
.cf:after {
    content: ' ';
    display: table;
}

.cf:after {
    clear: both;
}