@import url(https://fonts.googleapis.com/css?family=Merriweather);

html,
body {
    /* background: #f1f1f1; */
    font-family: 'Merriweather', sans-serif;
    /* padding: 1em; */
}

h1 {
    text-align: center;
    color: #a8a8a8;
}

form {
    max-width: 600px;
    text-align: center;
    margin: 20px auto;
}

input,
textarea {
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    font-family: 'Merriweather', sans-serif;
    resize: none;
}

#input-submit {
    color: rgb(34, 31, 31);
    font-size: 12pt;
    background: #4F81C5;
    cursor: pointer;
}

#input-submit:hover {
    box-shadow: 0 2px 2px 2px #07218771;
    filter: drop-shadow(4px 10px 6px #556888);
}

.TextFormHome {
    height: 258px;
}
.inputForm{
    /* background-color: #dae9fd93; */
    background-color: #a8c4f12f;
}
.CardFormulario2{

    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgb(255, 255, 255)5%,rgba(172, 201, 240, 0.5) 100%);
    /* background: linear-gradient(100deg, rgba(2,0,36,1) 0%, rgb(177, 177, 180)5%, rgb(128, 227, 247) 100%); */
    border-radius: 8px;
}
.CardFormulario3{
    border-radius: 8px;
}
/* Letra */
h5.h5tama18 {
    /* background-color: #E1E4EB; */
    /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
    padding: 1px;
  }
  
  @media screen and (min-width: 901px) {
    h5.h5tama18 {
      font-size: 15px;
    }
  }
  
  @media screen and (max-width: 1070px) {
    h5.h5tama18 {
      font-size: 13px;    
    }
  }

  @media screen and (max-width: 1060px) {
    h5.h5tama18 {
      font-size: 11px;    
    }
  }
/* Letra */

.Con18For{
    width: 99%;
}
.MovilidadCheck{
    /* position: absolute; */
    margin-left: -420px;
}
.FormularioCarrusel{
    width: 98.5%;
}
.FormularioCarrusel821{
    width: 100%;
}
.Con18For2{
    width: 90%;
}
.half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
}

.right {
    width: 50%;
}

.left {
    margin-right: 2%;
}

@media (max-width: 480px) {
    .half {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
}

.cf:before,
.cf:after {
    content: ' ';
    display: table;
}

.cf:after {
    clear: both;
}

.FondoDatosContacto{
    /* background-color: #22252b2c; */
    background: linear-gradient(360deg, #1122299c,#dff1f85c,#082d3b2a 115.71%);
}
.FondoDatosContactoLetra{
    color: #252729;
    filter: drop-shadow(4px 10px 6px #556888);

}