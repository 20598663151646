.img-size{
	height: 450px;
	width: 700px;
	background-size: cover;
	overflow: hidden;
}
.ContaSerInte{
	width: 92%;
	text-align: center;

}
.modal-content {
   width: 80%;
  border:none;
}
.modal-body {
   padding: 0;
}

.

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009be1' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
	width: 30px;
	height: 48px;
}
.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009be1' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
	width: 30px;
	height: 48px;
}

/* letter */
button.Carru2 {
	/* background-color: #E1E4EB; */
	filter: drop-shadow(9px 8px 10px #b3aeae);
	padding: 1px;
	margin-top: -2px;
  }
  
  @media screen and (min-width: 1001px) {
	button.Carru2 {
	  font-size: 11.8pt;
	}
  }
  
  @media screen and (max-width: 1085px) {
	button.Carru2 {
	  font-size: 11px;    
	}
  }

  button.CarruDatos {
	/* background-color: #E1E4EB; */
	filter: drop-shadow(9px 8px 10px #b3aeae);
	padding: 1px;
  }
  
  @media screen and (min-width: 1001px) {
	button.CarruDatos {
	  font-size: 11.8pt;
	  margin-top: 7px;
	}
  }
  
  @media screen and (max-width: 1085px) {
	button.CarruDatos {
	  font-size: 11px;    
	}
  }

  
  button.CarruPrimer {
	/* background-color: #E1E4EB; */
	filter: drop-shadow(9px 8px 10px #b3aeae);
	padding: 1px;
  }
  
  @media screen and (min-width: 1001px) {
	button.CarruPrimer {
	  font-size: 11.8pt;
	  margin-top: 10px;
	}
  }
  
  @media screen and (max-width: 1085px) {
	button.CarruPrimer {
	  font-size: 11px;    
	}
  }

  button.CarruControl {
	/* background-color: #E1E4EB; */
	filter: drop-shadow(9px 8px 10px #b3aeae);
	padding: 1px;
  }
  
  @media screen and (min-width: 1001px) {
	button.CarruControl {
	  font-size: 11.8pt;
	  margin-top: -6px;
	}
  }
  
  @media screen and (max-width: 1085px) {
	button.CarruControl {
	  font-size: 11px;    
	}
  }
/* letter */