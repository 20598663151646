.jumbotron {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 0pt;
    padding-right: 0pt;
    margin-bottom: 0;
    background:url("../../IMG/Jumbotron/2.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
  }
  
  .imafi{
     position:fixed;
     display: flex;
     bottom: 5%;
     right:0%;
     width:12.5%;
  }

  .imafi4{
    position:fixed;
    display: flex;
    bottom: 0%;
    left:-2%;
    width:13%;
    filter: drop-shadow(10px 10px 15px rgb(137, 137, 217));
    border-radius: 130px 130px 150px 140px;
 }
  .imafi3{
    margin-top: 4%;
    filter: brightness(100%);
    /* border-style: dashed ; */
    /* filter: contrast(0%); */
    /* filter: drop-shadow(10px 10px 15px white); */
    /* filter:unset; */
    /* filter: grayscale(100%); */
    /* filter: opacity(-10%); */
    /* filter: saturate(-300%); */
    /* filter: sepia(-500%); */
    position: relative;
    display:block;
    
    /* text-decoration: none; */
    /* overflow:hidden; */
    z-index: 1;
 }

.em1{
  /* position: calc(0px,0px); */
  background-position: top center;
  margin-top: -5%;
  /* background: linear-gradient(#BAB7D4, #292563); */
  background-image: url('../../IMG/Servicios/Automatiza.png');
}
.fondo11{
  border-color: #292563;
  background-color: #C0C8DE;
}

 .imafiA1{
   margin-top: -1%;
  /* filter: brightness(100%); */
  /* filter: contrast(0%); */
  filter: drop-shadow(10px 10px 15px rgb(137, 137, 217));
  /* filter:unset; */
  /* filter: grayscale(100%); */
  /* filter: opacity(-10%); */
  /* filter: saturate(-300%); */
  /* filter: sepia(-500%); */
  position: relative;
  height: 30%;
  width: 47%;
  /* text-decoration: none; */
  /* overflow:hidden; */
}
.imafi span {
  position:relative;
  display: block;
  background:url(/src/IMG/Logo/12564-tecnologia.jpg) no-repeat;
  background-position: -300px 0px;
}
.imafi3:hover span{
  background-position: 300px 0px;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1.5s;
  transition-property: all;
  transition-duration: 1.5s;
}
  .imafi2{
    position:fixed;
    display: flex;
    top:0%;
     right:0%;
     left: 0%;
     width:100%;
     height: 71%;
    opacity: 1;
    z-index: 0;
 }
  .HProgJ{
    padding-top: 6rem;
    padding-bottom: 6rem;
    background:url("../../IMG/Jumbotron/2.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
  }
  .jumbotron-heading {
    font-weight: 300;
  }
  
  .jumbotron .container {
    text-align: center;
    max-width: 40rem;
    background: rgba(0,0,0,0.2);
    padding: 2rem;
    color: white;
  }
  
  .jumbotron h3 {
    
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 14pt;
  }
  .jumbotron h1 {
    
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 28pt;
  }
  button {
    background-color: #D4EEFF;
    border: #3C1C6B;
    color: #3C1C6B;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 6px;
    padding-top: 6px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    border-radius:50px;
    
  }
  
  .HProgJ .container {
    text-align: center;
    max-width: 40rem;
    background: rgba(0,0,0,0.2);
    padding: 2rem;
    color: white;
  }
  
  .HProgJ h3 {
    
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 14pt;
  }
  .HProgJ h1 {
    
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 28pt;
  }