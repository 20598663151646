@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #696A6F;
  color: #D9D9D8;
  font-weight: 500;
  /* background-color: #1163E5; */
  /* color:steelblue; */
}

.cont {
  width: 90%;
  align-items: center;
  text-align: center;
}

.cont2 {
  width: 90%;
  /* justify-self: center; */
  align-items: center;
  text-align: center;
}

.US2 {
  border-radius: 1px;
}

h1 {
  color: #292563;
  filter: drop-shadow(9px 8px 10px #1163E5);
  /* font-size: 54pt; */
  font-weight: bold;
}

h2 {
  color: #292563;
  font-size: 24pt;
  font-weight: bold;
  filter: drop-shadow(4px 2px 6px #556888);

}

h3 {
  /* color: #6a5e80; */
  color: #292563;
  filter: drop-shadow(9px 8px 10px #1163E5);
  font-size: 14pt;
}

h4 {
  color: #292563;
  font-size: 14pt;
}

h5 {
  color: #292563;
}

h6 {
  color: white;
  font-size: 12pt;
  font-weight: bold;
}

h7 {
  color: #292563;
  font-size: 11pt;
}

.LetraCardSERV {
  font-family: 'EB Garamond', serif;
  color: #edf2f7;
  font-size: 16pt;
  filter: drop-shadow(1px 1px 3px #48494b);
}

.TitH8 {
  /* color: #6a5e80; */
  color: #f5f5f5;
  filter: drop-shadow(1px 4px 12px #edf2fa);

}

/* Cards letter */

p.TitH9 {
  padding: 1px;
  color: #f5f5f5;
  filter: drop-shadow(1px 4px 12px #edf2fa);
}

@media screen and (min-width: 900px) {
  p.TitH9 {
    font-size: 21px;
  }
}

@media screen and (max-width: 1100px) {
  p.TitH9 {
    font-size: 17px;
  }
}

@media screen and (max-width: 1000px) {
  p.TitH9 {
    font-size: 15px;
  }
}

@media screen and (max-width: 940px) {
  p.TitH9 {
    font-size: 14px;
  }
}

/* card letter */

.hhh {
  left: -7px;
  position: relative;
  float: left;
}

/* Responsive letter */
.pru1 {
  /* filter: drop-shadow(4px 2px 6px #494949); */
  filter: drop-shadow(4px 2px 6px #556888);
}

h2.TitSolu {
  background-color: #E1E4EB;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
  padding: 1px;
}

@media screen and (min-width: 901px) {
  h2.TitSolu {
    font-size: 38px;
  }
}

@media screen and (max-width: 600px) {
  h2.TitSolu {
    font-size: 23px;
  }
}

/* Letter */

p {
  color: #696A6F;
}

button {
  align-content: center;
}

.img-responsive {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  resize: both;
}

.PruebaSNT8 {
  width: 100%;
  height: auto;
  /* border: 1px; */
}

.XD1 {
  width: 12rem;
  align-content: center;
  align-items: center;
  display: inline-block;
}

.XD2 {
  align-content: center;
  align-items: center;
}

.snt-btn {
  width: 30%;
}

.snt-btn1 {
  width: 30%;

}

.snt-btnA3 {
  width: 65%;
}

.XD3 {
  width: 14rem;
  align-content: center;
  align-items: center;
  /* display: inline-block; */
}

.FondoBot1 {
  position: absolute;
  background-color: #e1e4eb;
  height: 45px;
  z-index: -1;
}

.FondoBot2 {
  position: absolute;
  background-color: #e1e4eb;
  z-index: -1;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
  color: silver;
}

.containe {
  width: 85%;
}

.FondoBot3 {
  position: absolute;
  background-color: #e1e4eb;
  z-index: -1;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.row {
  display: flex;
  justify-content: space-around center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.FCMAC1 {
  background-image: url("../../IMG/Comercial1/Map_2.png");
}

.fondoClie {
  background-image: url('../../IMG/LogosEmpresas/OCA_ST_3.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.parrafo {
  font-size: 13pt;
}

.botonAjuste {
  margin-top: -33px;
}

.botonAjuste22 {
  margin-top: -33px;
}

.botonAjuste23 {
  margin-top: -33px;
}

.botonAjuste24 {
  margin-top: -34px;
}

::-webkit-scrollbar {
  width: 11.5px;
  background-color: #0075fa17;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(13, 126, 206, 0.178);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #5873adcc;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #105d81f1;
}

h2.HomeTeam {
  /* background-color: #E1E4EB; */
  /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
  padding: 1px;
}

@media screen and (min-width: 901px) {
  h2.HomeTeam {
    font-size: 38px;
  }
}

@media screen and (max-width: 950px) {
  h2.HomeTeam {
    font-size: 23px;
  }
}

.btnCADM {
  align-items: center;
  border-style: dashed groove none dotted;
  width: '18rem'
}

.BorderIma {
  /* background-color: #dff1f892; */
  background: linear-gradient(180deg, #dff1f892, #dff1f85c, #04192142 115.71%);
  border-radius: 0px 0px 20px 20px;
}

.PositionHomeSNT {
  width: 90%;
}
.UniPrincipal{
  background-color: #F0F0F8;
  height:800px;
  border: solid 5px #000;
  border-color: #000 transparent transparent transparent;
  border-radius: 20%/100px 100px 0px 0px;
}

.univdep {
  max-width: 100%;
  max-height: 100%;
}

.conDivUni {
  background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
  border-radius: 0px 10px 10px 0px;
  height: 84vh; 
}
.univdep2 {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0px 100px 10px 0px;
}

.conDivUni2 {
  background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
  border-radius: 0px 400px 0px 0px;
  height: 150px;
  text-align: center;
}
.btnAlianza{
  background: linear-gradient(#f5e2c5, #eeb963, #F38E00 115.71%);
  color: #1a1e42;
  padding: 30px;
  text-align:center;
  font-size: 1.5vw;

}

.UniV8 {
  overflow-y: scroll;
  height: 550px;
}
.UniV8::-webkit-scrollbar {
  width: 5px;
}
.UniV8::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1C1853;
  border-radius: 20px;
}
.UniV8::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.UniV8::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}