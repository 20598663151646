.tamaño{
    width: 80%;
}

.tamaño-total{
    width: 100%;
}

.alinearTexto{
    text-align: left;
    columns: 100%;;
    margin: auto;
    flex-flow: column;
    text-align: justify;
    justify-items: auto;
}

.alinearTitulo{
    text-align: justify ;
    justify-items: auto;
}

.btn-position{
    bottom: 0px;
}

.linea{
    width: 65%;
    border: none;
    border-top: 2px solid;
    color: #36C7D2;
}
.CCC{
justify-items: auto;
}

.ipost1{
    width: 10px;
}
.contenido, .contenido-lateral{
 
    float: left;
    box-sizing: border-box;
}
.FIzquierda4{
    background-color: #666293;
    height: 50px;
    margin-top: 100%;
    transform: scale(0.7);
    border-radius: 50px;
    filter: drop-shadow(10px 10px 15px blue);
    /* border: solid 1px #ccc;
    transform: rotate(134deg) skewX(40deg) skewY(100deg); */
    /* border-right: none;
    background: #eee linear-gradient(white, #f1f1f1 37%, #e1e1e1 57%, #f6f6f6); */
}
.FDerecha5{
    background-color: #666293;
    height: 50px;
    margin-top: 100%;
    transform: scale(0.7);
    border-radius: 50px;
    filter: drop-shadow(10px 10px 15px blue);
}

.contenido{
    width: 40%;
}

.contenido-lateral{
    width: 60%;
}

.imagen{
    width: 100%; 
    height: 280px;
}
.ALI{
    width: 100px;
}
.SNT888{
    border-radius: 10px;
  }


@media screen and (min-width: 480px) and (max-width: 780px){
    
    .contenido, .contenido-lateral, .imagen{
        width: 100%;
    }

    .imagen{
        height: 20%;
    }
}

@media screen and (max-width: 580px){
    
    .contenido, .contenido-lateral, .imagen{
        width: 100%;
    }

    .imagen{
        height: 20%;
    }
}
