*{
  margin: 0;
  padding: 0;
}
header{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  transition: 0.7s;
  padding: 30px 20px;
  z-index: 10;
}
header.abajo{
  background:rgba(2, 29, 78, 0.829);
  padding: 12px 20px;
}

header .logo{
  position: relative;
  color: #0f2666;
  font-weight: bold;
  /* font-size: 2em; */
  letter-spacing: 2px;
  transition: 2px;
  text-decoration: none;
}

nav{
  display: flex;
  justify-content: center;
  align-items: center;
}
header ul{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

header ul li{
  list-style: none;
}

header ul li a{
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 15px;
  text-decoration: none;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 600;
  transition: 0.7s;
}

.zona1{
  /* position:relative;
  width: 100%;
  height: 100vh;
  background: url(../../IMG/Generales/Conference_1.png);
  background-size: cover;
  background-position: center center; */
}

.btnmenuPrincipal {
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.116);
  /* background-color: rgb(255, 255, 255); */
  color: black;
  padding: 1px 20px;
  font-size: 16px;
  cursor: pointer;

}

/* Green */
.btnmenuPrincipal2 {
  border-color: #4757e7;
  border-radius: 8px 0px 8px 0px;
  /* background-color: rgba(255, 255, 255, 0.116); */
  background-color: rgb(255, 255, 255);
  color: green;
}

.btnmenuPrincipal2:hover {
  /* background-color: #0476aaaf; */
  color: white;
}

header.abajo .logo,
header.abajo ul li a{
  /* color:rgba(16, 19, 197, 0.966); */
  color:#0F2666;
}
/* nav {
    border-radius:6px;    
    filter: drop-shadow(1px 1px 5px white);
  } */

.cambioC{
  filter: drop-shadow(0px 0px 4px #00006A);
  background-color: #C0C8DE;
  color: #2731e1;
}

.cambioC821{
  /* filter: drop-shadow(0px 0px 4px #ed1818); */
  background-color: #033670;
}
.letraSNT8211 {
  /* background-color: #3d2cf5; */
  white-space: nowrap;
  filter: drop-shadow(0px 0px 2px rgb(0, 30, 255));
  border-radius: 8px 0px 8px 0px;
  margin-left: 20px;
  text-align: center;
  
}

.letraSNT {
  /* background-color: #3d2cf5; */
  white-space: nowrap;
  filter: drop-shadow(0px 0px 2px rgb(0, 30, 255));
  border-radius: 8px 0px 8px 0px;
  margin-left: 10px;
  /* text-align: right; */
}

.ColorFondoMenu2{
  background-color: #3333b913;
}

.MenuSiggoAspel{
  background-color: #ffffff13;
  border: 0;
  color: #0F2666;
}

header .logo {
  /* background-color: #E1E4EB; */
  /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
  padding: 1px;
}

@media screen and (min-width: 901px) {
  header .logo {
    font-size: 29px;
  }
}

@media screen and (max-width: 1050px) {
  header .logo {
    font-size: 23px;    
  }
}

