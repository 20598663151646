.TamSNT{
    width: 100%;
    margin-left: 2.4%;
    max-width: 95%;
    /* background-color: black; */
}
.FIzquierda1{
    margin-right: 90%;
}
.FDerecha1{
    margin-left: 90%;
}
.SombraIma{
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    border-radius: 5px 5px 5px 5px;
}
.TipoLetraSNT2{
    font-size: 1pt;
    font: oblique bold 150% cursive;
    color: rgb(30, 30, 56);
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    
}
