.FondoCanaco{
    background-color: #484748;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.TituloCanaco{
    font-size: 20pt;
    color: aliceblue;
}
.videoCanaco{
    z-index: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
}
.CardAjuste{
    width: 100%;
    object-fit: cover;
    /* height: 100%; */
}

.SerInCana {
    overflow-y: scroll;
    height: 450px;
  }
  .SerInCana::-webkit-scrollbar {
    width: 4px;
  }
  .SerInCana::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1C1853;
    border-radius: 20px;
  }
  .SerInCana::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .SerInCana::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }


  .SerInCanaco {
    overflow-y: scroll;
    height: 30px;
  }
  .SerInCanaco::-webkit-scrollbar {
    width: 4px;
  }
  .SerInCanaco::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1C1853;
    border-radius: 20px;
  }
  .SerInCanaco::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .SerInCanaco::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }