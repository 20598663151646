@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@600&family=Playfair+Display:ital,wght@1,700&display=swap');

.XD {
  width: 16rem;
  height: 14rem;
  align-content: center;
  align-items: center;
  display: inline-block;
}

.row {
  display: flex;
  justify-content: space-around;
}

.SNTtit {
  color: #5c5c70;
}

.SNTtitColor {
  color: #55555e;

}


.Hola {
  color: aliceblue;
}

.MovSNT1 {
  margin-top: -3%;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.tipoLetraSNT1 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* font-family: 'Cormorant SC', serif;
  font-family: 'Playfair Display', serif; */
  font-size: 18pt;
  color: #0b055f;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.titSNTOCA {
  font-family: 'Cormorant SC', serif;
  font-family: 'Playfair Display', serif;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.LetraPQ {
  color: black;
  font-size: 15pt;
}

.Mov_text {
  padding-top: 80px;
}

.contenedor1 {
  position: relative;
}
.contene1 {
  position: relative;
}
.contene2 {
  position: absolute;
  /* margin-top: -180px; */
  top: 5rem;
  background-color: #140f5e;
  height: 41px;
  border-radius: 0px 8px 8px 0px;
}
.contene2Canaco {
  position: absolute;
  /* margin-top: -180px; */
  top: 5rem;
  background-color: #140f5e;
  height: 41px;
  border-radius: 0px 8px 8px 0px;
}
p.TitH9Canaco {
  padding: 1px;
  color: #f5f5f5;
  filter: drop-shadow(1px 4px 12px #edf2fa);
}

@media screen and (min-width: 900px) {
  p.TitH9Canaco {
    font-size: 21px;
  }
}

@media screen and (max-width: 1100px) {
  p.TitH9Canaco {
    font-size: 17px;
  }
}
@media screen and (max-width: 1000px) {
  p.TitH9Canaco {
    font-size: 15px;
  }
}
@media screen and (max-width: 940px) {
  p.TitH9Canaco {
    font-size: 14px;
  }
}


.contenedor2 {
  position: absolute;
  margin-top: -180px;
  background-color: #140f5e;
  height: 41px;
  border-radius: 0px 8px 8px 0px;
}


.contenedor3 {
  font-family: 'EB Garamond', serif;
  color: #202721;
  font-size: 16pt;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.Opaci {
  opacity: 0.7;
}

.Mov_text2 {
  padding-top: 60px;
}

.fondoSoluciones {
  background-image: url('../../IMG/SolucionesTecnologicas/Ima_fondo_oca.png');
  /* background-color: #292563; */
  filter: drop-shadow(9px 8px 10px #0c0b0b);
  fill-opacity: calc(5+1000*2/8);
}

.titSNT2 {
  font-size: 20pt;
  font-family: 'Cormorant SC', serif;
  font-family: 'Playfair Display', serif;
}

.imaSNT1 {
  width: 33%;
  text-align: center;
}

.imaSNT11 {
  border-radius: 10px;
}

.imaSNT3 {
  width: 60%;
  text-align: center;
}

.LogAspel {
  width: 112%;
  margin-top: 5%;
}
.TaSN {
  overflow-y: scroll;
  height: 80px;
}
.TaSN::-webkit-scrollbar {
  width: 5px;
}
.TaSN::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1C1853;
  border-radius: 20px;
}
.TaSN::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.TaSN::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}

/* SNT Scroll */
.ScrollSInfra {
  overflow-y: scroll;
  height: 150px;
}
.ScrollSInfra::-webkit-scrollbar {
  width: 5px;
}
.ScrollSInfra::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
  border-radius: 20px;
}
.ScrollSInfra::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.ScrollSInfra::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}
/* Scroll */

.movLogosAspel {
  margin-left: -15px;
}

.h8 {
  font-size: 14pt;
}

.SombraSNT {
  filter: drop-shadow(0px 0px 3px blue);
}

.SombraCARDSNT {
  filter: drop-shadow(0px 0px 1.5px rgb(1, 1, 54));
}

.CardFondo {
  background-color: #eff3fb;
  font-size: 12pt;
}

.Fot1 {

  position: absolute;
  bottom: 0px;
  width: 100%;
}

.Fot2 {
  margin-top: 35px;
}

.SerIn {
  overflow-y: scroll;
  height: 65px;
}
.SerIn::-webkit-scrollbar {
  width: 5px;
}
.SerIn::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1C1853;
  border-radius: 20px;
}
.SerIn::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.SerIn::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}

.SoTecno821{
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.473));
}