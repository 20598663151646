@import url(https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@600&family=Playfair+Display:ital,wght@1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(./IMG/Logo/12563-tecnologia.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
   */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
  color: #bd1202;
  font-size: 20px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
label{
 font-size: 10pt; 
}


*{
  margin: 0;
  padding: 0;
}
header{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  transition: 0.7s;
  padding: 30px 20px;
  z-index: 10;
}
header.abajo{
  background:rgba(2, 29, 78, 0.829);
  padding: 12px 20px;
}

header .logo{
  position: relative;
  color: #0f2666;
  font-weight: bold;
  /* font-size: 2em; */
  letter-spacing: 2px;
  transition: 2px;
  text-decoration: none;
}

nav{
  display: flex;
  justify-content: center;
  align-items: center;
}
header ul{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

header ul li{
  list-style: none;
}

header ul li a{
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 15px;
  text-decoration: none;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 600;
  transition: 0.7s;
}

.zona1{
  /* position:relative;
  width: 100%;
  height: 100vh;
  background: url(../../IMG/Generales/Conference_1.png);
  background-size: cover;
  background-position: center center; */
}

.btnmenuPrincipal {
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.116);
  /* background-color: rgb(255, 255, 255); */
  color: black;
  padding: 1px 20px;
  font-size: 16px;
  cursor: pointer;

}

/* Green */
.btnmenuPrincipal2 {
  border-color: #4757e7;
  border-radius: 8px 0px 8px 0px;
  /* background-color: rgba(255, 255, 255, 0.116); */
  background-color: rgb(255, 255, 255);
  color: green;
}

.btnmenuPrincipal2:hover {
  /* background-color: #0476aaaf; */
  color: white;
}

header.abajo .logo,
header.abajo ul li a{
  /* color:rgba(16, 19, 197, 0.966); */
  color:#0F2666;
}
/* nav {
    border-radius:6px;    
    filter: drop-shadow(1px 1px 5px white);
  } */

.cambioC{
  filter: drop-shadow(0px 0px 4px #00006A);
  background-color: #C0C8DE;
  color: #2731e1;
}

.cambioC821{
  /* filter: drop-shadow(0px 0px 4px #ed1818); */
  background-color: #033670;
}
.letraSNT8211 {
  /* background-color: #3d2cf5; */
  white-space: nowrap;
  filter: drop-shadow(0px 0px 2px rgb(0, 30, 255));
  border-radius: 8px 0px 8px 0px;
  margin-left: 20px;
  text-align: center;
  
}

.letraSNT {
  /* background-color: #3d2cf5; */
  white-space: nowrap;
  filter: drop-shadow(0px 0px 2px rgb(0, 30, 255));
  border-radius: 8px 0px 8px 0px;
  margin-left: 10px;
  /* text-align: right; */
}

.ColorFondoMenu2{
  background-color: #3333b913;
}

.MenuSiggoAspel{
  background-color: #ffffff13;
  border: 0;
  color: #0F2666;
}

header .logo {
  /* background-color: #E1E4EB; */
  /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
  padding: 1px;
}

@media screen and (min-width: 901px) {
  header .logo {
    font-size: 29px;
  }
}

@media screen and (max-width: 1050px) {
  header .logo {
    font-size: 23px;    
  }
}


.jumbotron {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 0pt;
    padding-right: 0pt;
    margin-bottom: 0;
    background:url(/static/media/2.f0b14496.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
  }
  
  .imafi{
     position:fixed;
     display: flex;
     bottom: 5%;
     right:0%;
     width:12.5%;
  }

  .imafi4{
    position:fixed;
    display: flex;
    bottom: 0%;
    left:-2%;
    width:13%;
    filter: drop-shadow(10px 10px 15px rgb(137, 137, 217));
    border-radius: 130px 130px 150px 140px;
 }
  .imafi3{
    margin-top: 4%;
    filter: brightness(100%);
    /* border-style: dashed ; */
    /* filter: contrast(0%); */
    /* filter: drop-shadow(10px 10px 15px white); */
    /* filter:unset; */
    /* filter: grayscale(100%); */
    /* filter: opacity(-10%); */
    /* filter: saturate(-300%); */
    /* filter: sepia(-500%); */
    position: relative;
    display:block;
    
    /* text-decoration: none; */
    /* overflow:hidden; */
    z-index: 1;
 }

.em1{
  /* position: calc(0px,0px); */
  background-position: top center;
  margin-top: -5%;
  /* background: linear-gradient(#BAB7D4, #292563); */
  background-image: url(/static/media/Automatiza.d6810d95.png);
}
.fondo11{
  border-color: #292563;
  background-color: #C0C8DE;
}

 .imafiA1{
   margin-top: -1%;
  /* filter: brightness(100%); */
  /* filter: contrast(0%); */
  filter: drop-shadow(10px 10px 15px rgb(137, 137, 217));
  /* filter:unset; */
  /* filter: grayscale(100%); */
  /* filter: opacity(-10%); */
  /* filter: saturate(-300%); */
  /* filter: sepia(-500%); */
  position: relative;
  height: 30%;
  width: 47%;
  /* text-decoration: none; */
  /* overflow:hidden; */
}
.imafi span {
  position:relative;
  display: block;
  background:url(/src/IMG/Logo/12564-tecnologia.jpg) no-repeat;
  background-position: -300px 0px;
}
.imafi3:hover span{
  background-position: 300px 0px;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1.5s;
  transition-property: all;
  transition-duration: 1.5s;
}
  .imafi2{
    position:fixed;
    display: flex;
    top:0%;
     right:0%;
     left: 0%;
     width:100%;
     height: 71%;
    opacity: 1;
    z-index: 0;
 }
  .HProgJ{
    padding-top: 6rem;
    padding-bottom: 6rem;
    background:url(/static/media/2.f0b14496.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
  }
  .jumbotron-heading {
    font-weight: 300;
  }
  
  .jumbotron .container {
    text-align: center;
    max-width: 40rem;
    background: rgba(0,0,0,0.2);
    padding: 2rem;
    color: white;
  }
  
  .jumbotron h3 {
    
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 14pt;
  }
  .jumbotron h1 {
    
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 28pt;
  }
  button {
    background-color: #D4EEFF;
    border: #3C1C6B;
    color: #3C1C6B;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 6px;
    padding-top: 6px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    border-radius:50px;
    
  }
  
  .HProgJ .container {
    text-align: center;
    max-width: 40rem;
    background: rgba(0,0,0,0.2);
    padding: 2rem;
    color: white;
  }
  
  .HProgJ h3 {
    
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 14pt;
  }
  .HProgJ h1 {
    
    color: white;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 28pt;
  }
.YII{
    width: 100%;
    height: 140pt;
}
.Aslider{
    width: 100%;
    height: 280pt;
}
.XD {
  width: 16rem;
  height: 14rem;
  align-content: center;
  align-items: center;
  display: inline-block;
}

.row {
  display: flex;
  justify-content: space-around;
}

.SNTtit {
  color: #5c5c70;
}

.SNTtitColor {
  color: #55555e;

}


.Hola {
  color: aliceblue;
}

.MovSNT1 {
  margin-top: -3%;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.tipoLetraSNT1 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* font-family: 'Cormorant SC', serif;
  font-family: 'Playfair Display', serif; */
  font-size: 18pt;
  color: #0b055f;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.titSNTOCA {
  font-family: 'Cormorant SC', serif;
  font-family: 'Playfair Display', serif;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.LetraPQ {
  color: black;
  font-size: 15pt;
}

.Mov_text {
  padding-top: 80px;
}

.contenedor1 {
  position: relative;
}
.contene1 {
  position: relative;
}
.contene2 {
  position: absolute;
  /* margin-top: -180px; */
  top: 5rem;
  background-color: #140f5e;
  height: 41px;
  border-radius: 0px 8px 8px 0px;
}
.contene2Canaco {
  position: absolute;
  /* margin-top: -180px; */
  top: 5rem;
  background-color: #140f5e;
  height: 41px;
  border-radius: 0px 8px 8px 0px;
}
p.TitH9Canaco {
  padding: 1px;
  color: #f5f5f5;
  filter: drop-shadow(1px 4px 12px #edf2fa);
}

@media screen and (min-width: 900px) {
  p.TitH9Canaco {
    font-size: 21px;
  }
}

@media screen and (max-width: 1100px) {
  p.TitH9Canaco {
    font-size: 17px;
  }
}
@media screen and (max-width: 1000px) {
  p.TitH9Canaco {
    font-size: 15px;
  }
}
@media screen and (max-width: 940px) {
  p.TitH9Canaco {
    font-size: 14px;
  }
}


.contenedor2 {
  position: absolute;
  margin-top: -180px;
  background-color: #140f5e;
  height: 41px;
  border-radius: 0px 8px 8px 0px;
}


.contenedor3 {
  font-family: 'EB Garamond', serif;
  color: #202721;
  font-size: 16pt;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.Opaci {
  opacity: 0.7;
}

.Mov_text2 {
  padding-top: 60px;
}

.fondoSoluciones {
  background-image: url(/static/media/Ima_fondo_oca.c11f0eaf.png);
  /* background-color: #292563; */
  filter: drop-shadow(9px 8px 10px #0c0b0b);
  fill-opacity: calc(5+1000*2/8);
}

.titSNT2 {
  font-size: 20pt;
  font-family: 'Cormorant SC', serif;
  font-family: 'Playfair Display', serif;
}

.imaSNT1 {
  width: 33%;
  text-align: center;
}

.imaSNT11 {
  border-radius: 10px;
}

.imaSNT3 {
  width: 60%;
  text-align: center;
}

.LogAspel {
  width: 112%;
  margin-top: 5%;
}
.TaSN {
  overflow-y: scroll;
  height: 80px;
}
.TaSN::-webkit-scrollbar {
  width: 5px;
}
.TaSN::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1C1853;
  border-radius: 20px;
}
.TaSN::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.TaSN::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}

/* SNT Scroll */
.ScrollSInfra {
  overflow-y: scroll;
  height: 150px;
}
.ScrollSInfra::-webkit-scrollbar {
  width: 5px;
}
.ScrollSInfra::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
  border-radius: 20px;
}
.ScrollSInfra::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.ScrollSInfra::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}
/* Scroll */

.movLogosAspel {
  margin-left: -15px;
}

.h8 {
  font-size: 14pt;
}

.SombraSNT {
  filter: drop-shadow(0px 0px 3px blue);
}

.SombraCARDSNT {
  filter: drop-shadow(0px 0px 1.5px rgb(1, 1, 54));
}

.CardFondo {
  background-color: #eff3fb;
  font-size: 12pt;
}

.Fot1 {

  position: absolute;
  bottom: 0px;
  width: 100%;
}

.Fot2 {
  margin-top: 35px;
}

.SerIn {
  overflow-y: scroll;
  height: 65px;
}
.SerIn::-webkit-scrollbar {
  width: 5px;
}
.SerIn::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1C1853;
  border-radius: 20px;
}
.SerIn::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.SerIn::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}

.SoTecno821{
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.473));
}
.let1 {
     font-size: 8pt;
}

.containerSNT {
     width: 90%;
}

.StyleSNT {
     /* background: linear-gradient(#eff3fb,#dbdfe7,rgb(16, 70, 80) 115.71%); */
     background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
     border-radius: 10px;
     /* background: ; */
}

.StyleSNT2 {
     background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
     border-radius: 10px 0px 0px 10px;
     height: 125px;
}
.StyleSNT3 {
     background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
     border-radius: 0px 0px 0px 0px;
     height: 125px;
}
.footLog{
     max-width: 100%;
     max-height: 100%;
}
.StyleSNT4 {
     background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
     border-radius: 0px 10px 10px 0px;
     height: 125px;
}


.fotcol {
     background-color: #C0C8DE;
     z-index: -1;
}

.imgSNT {
     border-radius: 15px;
}

.fondFoote {
     background-color: transparent;
     z-index: 0;
}
.IDE1 {
    filter: drop-shadow(8px 0px 5px rgb(115, 115, 214));
    width: 100%;
    height: 88%;
    position: absolute;
}

.ConIn1 {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: left;
    justify-content: left;
}

.ima1p-1 {
    float: right;
}

.ConServicios {
    width: 90%;
}

.MS18 {
    /* style={{ backgroundColor: '#eff3fb' }} */
    border-radius: 10px;
    background-color: #eceef1;
    /* background-color: #c5cbd5; */
}

.AjuSNT {
    filter: drop-shadow(5px 0px 5px rgb(115, 115, 214));
    border-radius: 10px 0px 0px 10px;
}

.AjuSNT2 {
    filter: drop-shadow(8px 0px 5px rgb(90, 90, 189));
    border-radius: 0px 10px 10px 0px;
}

.IDE12 {
    width: 70%;
    border-radius: 200px 200px;
}

.IDE12SNT {
    width: 95%;
    border-radius: 100px;
    opacity: 0.9;
    filter: drop-shadow(5px 0px 5px rgb(186, 186, 190));
}

.IDE18SNT {
    width: 95%;
    border-radius: 100px;
    opacity: 0.9;
    filter: drop-shadow(5px 0px 5px rgb(186, 186, 190));
}

.IDE2 {
    width: 30%;
    border-radius: 200px 200px;
}

.IDE11 {
    width: 90%;
}

.parrafo {
    color: #696A6F;
    font-size: 13pt;
}

.DDD {
    position: absolute;
    margin-top: 17%;
    margin-left: 3%;
}

.pruebaas {
    margin-right: auto;
}

.par1 {
    font-size: 14pt;
}

.TpLetraSNT {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font: oblique 150% cursive;
    color: rgb(30, 30, 56);
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));

}

.BRSNT {
    border-radius: 10px;
}

.BRSNT2 {
    border-radius: 20px;
}

.SombrillaSNT {
    filter: drop-shadow(1px 1px 10px rgb(18, 18, 50));
}

.AcomodoSNT {
    border-radius: 10px;
    filter: drop-shadow(1px 1px 10px rgb(18, 18, 50));
}

/* Fondo Certificados */
.Acomodo2SNT {
    position: absolute;
    opacity: 0.9;
    border-radius: 10px;
    filter: drop-shadow(1px 1px 10px rgb(184, 184, 203));
}

.SomSNT {
    filter: drop-shadow(1px 1px 10px rgb(184, 184, 203));
}

.Acomodo21SNT {
    position: relative;
    margin-top: -0.2%;
    margin-left: -0%;
    opacity: 0.9;

}

.Acomodo22SNT {
    position: relative;
    margin-top: 0%;
    margin-left: 0%;
    opacity: 0.6;
}

.Acomodo23SNT {
    position: relative;
    margin-top: 0%;
    margin-left: 0%;
    opacity: 0.8;
}

.Acomodo24SNT {
    position: relative;
    margin-top: -72%;
    margin-left: 0%;
    opacity: 0.8;
}

.Acomodo25SNT {
    position: relative;
    margin-top: -72%;
    margin-left: 0%;
    opacity: 0.8;
}

.AcomodoSNT1 {
    position: relative;
}

h1.InfraJumbo {
    /* background-color: #E1E4EB; */
    /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
    padding: 1px;
}

@media screen and (min-width: 901px) {
    h1.InfraJumbo {
        font-size: 38px;
    }
}

@media screen and (max-width: 950px) {
    h1.InfraJumbo {
        font-size: 23px;
    }
}

.Container-Servicios {
    width: 99%;
    /* padding-top: 65px; */
}

.SnippDiv {
    display: flex;
    justify-content: space-between;
}

.PdVServicios {
    width: 100%;
    position: relative;
    /* para poder posicionar el texto de forma absoluta */
    display: inline-block;
    /* para que solo ocupe el ancho de la imagen */
}

.PdVServiciosTexto {
    position: absolute;
    /* posición absolute con respecto al padre */
    top: 0;
    /* posicionada en la esquina inferior derecha */
    left: 1rem;
}

.CursoSAspel {
    background-color: #aacbf48c;
}

.fondoSistemaAs {
    /* background-color: #00006a13; */
    background-color: #2ba4f546;
}

.v-line {
    border-left: thin solid black;
    height: 91%;
    right: 0%;
    position: absolute;
}

.AspelBANCOIMA {
    color: #c1e96c;
}

.CotizaCurso {
    border-radius: 10px;
    filter: drop-shadow(2px 0px 4px rgb(5, 14, 82));
}

.InterLineCurso{
    line-height: 1.3;
}

.VideoFormatos{
    width: 100%;
    height: auto;
    border-radius: 10px;
    filter: drop-shadow(2px 0px 4px rgb(5, 14, 82));
}


/*  */
p.PdVServiciosTexto {
    /* background-color: #E1E4EB; */
    /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
    padding: 1px;
}

@media screen and (min-width: 901px) {
    p.PdVServiciosTexto {
        font-size: 40px;
    }
}

@media screen and (max-width: 950px) {
    p.PdVServiciosTexto {
        font-size: 23px;
    }
}

/* Scroll X */
.CursoDisponibleA {
    overflow-y: scroll;
    height: 65px;
}

.SerIn::-webkit-scrollbar {
    width: 5px;
}

.SerIn::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1C1853;
    border-radius: 20px;
}

.SerIn::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
}

.SerIn::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
}
.TamSNT{
    width: 100%;
    margin-left: 2.4%;
    max-width: 95%;
    /* background-color: black; */
}
.FIzquierda1{
    height: 25%;
    margin-right: 90%;
    
}
.FDerecha1{
    height: 25%;
    margin-left: 90%;
}
.SombraSNTCarru{
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
}
.BorderSNT{
    border-radius: 10px;
}

.SombraIma{
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    border-radius: 5px 5px 5px 5px;
}
.TipoLetraSNT{
    font: oblique bold 200% cursive;
    color: rgb(30, 30, 56);
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    
}
.TamSNT{
    width: 100%;
    margin-left: 2.4%;
    max-width: 95%;
    /* background-color: black; */
}
.FIzquierda1{
    margin-right: 90%;
}
.FDerecha1{
    margin-left: 90%;
}
.SombraIma{
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    border-radius: 5px 5px 5px 5px;
}
.TipoLetraSNT2{
    font-size: 1pt;
    font: oblique bold 150% cursive;
    color: rgb(30, 30, 56);
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    
}

.TamSNT{
    width: 100%;
    margin-left: 2.4%;
    max-width: 95%;
    /* background-color: black; */
}
.FIzquierda1{
    margin-right: 90%;
}
.FDerecha1{
    margin-left: 90%;
}
.SombraIma{
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    border-radius: 5px 5px 5px 5px;
}
.TipoLetraSNT{
    font: oblique bold 200% cursive;
    color: rgb(30, 30, 56);
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    
}
.Tama2{
    font-size: 14pt;
    color: #696A6F;
}
.Tama23{
    font-size: 14pt;
    color: rgba(102, 32, 207, 0.271);
}
p.Tama23 {
    /* background-color: #E1E4EB; */
    filter: drop-shadow(9px 8px 10px #0c0b0b);
    padding: 1px;
  }
  
  @media screen and (min-width: 901px) {
    p.Tama23 {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 1050px) {
    p.Tama23 {
      font-size: 12px;    
    }
  }

  @media screen and (max-width: 1100px) {
    p.Tama23 {
      font-size: 9px;    
    }
  }
.TamSNT{
    width: 100%;
    margin-left: 2.4%;
    max-width: 95%;
    /* background-color: black; */
}
.FIzquierda1{
    margin-right: 90%;
}
.FDerecha1{
    margin-left: 90%;
}
.SombraIma{
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    border-radius: 5px 5px 5px 5px;
}
.TipoLetraSNT{
    font: oblique bold 200% cursive;
    color: rgb(30, 30, 56);
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));
    
}

body {
  font-family: 'Roboto', sans-serif;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #696A6F;
  color: #D9D9D8;
  font-weight: 500;
  /* background-color: #1163E5; */
  /* color:steelblue; */
}

.cont {
  width: 90%;
  align-items: center;
  text-align: center;
}

.cont2 {
  width: 90%;
  /* justify-self: center; */
  align-items: center;
  text-align: center;
}

.US2 {
  border-radius: 1px;
}

h1 {
  color: #292563;
  filter: drop-shadow(9px 8px 10px #1163E5);
  /* font-size: 54pt; */
  font-weight: bold;
}

h2 {
  color: #292563;
  font-size: 24pt;
  font-weight: bold;
  filter: drop-shadow(4px 2px 6px #556888);

}

h3 {
  /* color: #6a5e80; */
  color: #292563;
  filter: drop-shadow(9px 8px 10px #1163E5);
  font-size: 14pt;
}

h4 {
  color: #292563;
  font-size: 14pt;
}

h5 {
  color: #292563;
}

h6 {
  color: white;
  font-size: 12pt;
  font-weight: bold;
}

h7 {
  color: #292563;
  font-size: 11pt;
}

.LetraCardSERV {
  font-family: 'EB Garamond', serif;
  color: #edf2f7;
  font-size: 16pt;
  filter: drop-shadow(1px 1px 3px #48494b);
}

.TitH8 {
  /* color: #6a5e80; */
  color: #f5f5f5;
  filter: drop-shadow(1px 4px 12px #edf2fa);

}

/* Cards letter */

p.TitH9 {
  padding: 1px;
  color: #f5f5f5;
  filter: drop-shadow(1px 4px 12px #edf2fa);
}

@media screen and (min-width: 900px) {
  p.TitH9 {
    font-size: 21px;
  }
}

@media screen and (max-width: 1100px) {
  p.TitH9 {
    font-size: 17px;
  }
}

@media screen and (max-width: 1000px) {
  p.TitH9 {
    font-size: 15px;
  }
}

@media screen and (max-width: 940px) {
  p.TitH9 {
    font-size: 14px;
  }
}

/* card letter */

.hhh {
  left: -7px;
  position: relative;
  float: left;
}

/* Responsive letter */
.pru1 {
  /* filter: drop-shadow(4px 2px 6px #494949); */
  filter: drop-shadow(4px 2px 6px #556888);
}

h2.TitSolu {
  background-color: #E1E4EB;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
  padding: 1px;
}

@media screen and (min-width: 901px) {
  h2.TitSolu {
    font-size: 38px;
  }
}

@media screen and (max-width: 600px) {
  h2.TitSolu {
    font-size: 23px;
  }
}

/* Letter */

p {
  color: #696A6F;
}

button {
  align-content: center;
}

.img-responsive {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  resize: both;
}

.PruebaSNT8 {
  width: 100%;
  height: auto;
  /* border: 1px; */
}

.XD1 {
  width: 12rem;
  align-content: center;
  align-items: center;
  display: inline-block;
}

.XD2 {
  align-content: center;
  align-items: center;
}

.snt-btn {
  width: 30%;
}

.snt-btn1 {
  width: 30%;

}

.snt-btnA3 {
  width: 65%;
}

.XD3 {
  width: 14rem;
  align-content: center;
  align-items: center;
  /* display: inline-block; */
}

.FondoBot1 {
  position: absolute;
  background-color: #e1e4eb;
  height: 45px;
  z-index: -1;
}

.FondoBot2 {
  position: absolute;
  background-color: #e1e4eb;
  z-index: -1;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
  color: silver;
}

.containe {
  width: 85%;
}

.FondoBot3 {
  position: absolute;
  background-color: #e1e4eb;
  z-index: -1;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

.row {
  display: flex;
  justify-content: space-around center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.FCMAC1 {
  background-image: url(/static/media/Map_2.f9caf3cf.png);
}

.fondoClie {
  background-image: url(/static/media/OCA_ST_3.91410054.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.parrafo {
  font-size: 13pt;
}

.botonAjuste {
  margin-top: -33px;
}

.botonAjuste22 {
  margin-top: -33px;
}

.botonAjuste23 {
  margin-top: -33px;
}

.botonAjuste24 {
  margin-top: -34px;
}

::-webkit-scrollbar {
  width: 11.5px;
  background-color: #0075fa17;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(13, 126, 206, 0.178);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #5873adcc;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #105d81f1;
}

h2.HomeTeam {
  /* background-color: #E1E4EB; */
  /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
  padding: 1px;
}

@media screen and (min-width: 901px) {
  h2.HomeTeam {
    font-size: 38px;
  }
}

@media screen and (max-width: 950px) {
  h2.HomeTeam {
    font-size: 23px;
  }
}

.btnCADM {
  align-items: center;
  border-style: dashed groove none dotted;
  width: '18rem'
}

.BorderIma {
  /* background-color: #dff1f892; */
  background: linear-gradient(180deg, #dff1f892, #dff1f85c, #04192142 115.71%);
  border-radius: 0px 0px 20px 20px;
}

.PositionHomeSNT {
  width: 90%;
}
.UniPrincipal{
  background-color: #F0F0F8;
  height:800px;
  border: solid 5px #000;
  border-color: #000 transparent transparent transparent;
  border-radius: 20%/100px 100px 0px 0px;
}

.univdep {
  max-width: 100%;
  max-height: 100%;
}

.conDivUni {
  background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
  border-radius: 0px 10px 10px 0px;
  height: 84vh; 
}
.univdep2 {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0px 100px 10px 0px;
}

.conDivUni2 {
  background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
  border-radius: 0px 400px 0px 0px;
  height: 150px;
  text-align: center;
}
.btnAlianza{
  background: linear-gradient(#f5e2c5, #eeb963, #F38E00 115.71%);
  color: #1a1e42;
  padding: 30px;
  text-align:center;
  font-size: 1.5vw;

}

.UniV8 {
  overflow-y: scroll;
  height: 550px;
}
.UniV8::-webkit-scrollbar {
  width: 5px;
}
.UniV8::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1C1853;
  border-radius: 20px;
}
.UniV8::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.UniV8::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}
.img-size1{
	height: 600px;
	width: 100%;
    /* display: block; */
	background-size: cover;
	overflow: hidden;
}
.modal-content {
   width: 700px;
  border:none;
}
.modal-body {
   padding: 0;
}
.carousel-control-prev-icon1 {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='silver' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
	width: 30px;
	height: 28px;
}
.carousel-control-next-icon1 {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='silver' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
	width: 30px;
	height: 28px;
}
html,
body {
    /* background: #f1f1f1; */
    font-family: 'Merriweather', sans-serif;
    /* padding: 1em; */
}

h1 {
    text-align: center;
    color: #a8a8a8;
}

form {
    max-width: 600px;
    text-align: center;
    margin: 20px auto;
}

input,
textarea {
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    font-family: 'Merriweather', sans-serif;
    resize: none;
}

#input-submit {
    color: rgb(34, 31, 31);
    font-size: 12pt;
    background: #4F81C5;
    cursor: pointer;
}

#input-submit:hover {
    box-shadow: 0 2px 2px 2px #07218771;
    filter: drop-shadow(4px 10px 6px #556888);
}

.TextFormHome {
    height: 258px;
}
.inputForm{
    /* background-color: #dae9fd93; */
    background-color: #a8c4f12f;
}
.CardFormulario2{

    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgb(255, 255, 255)5%,rgba(172, 201, 240, 0.5) 100%);
    /* background: linear-gradient(100deg, rgba(2,0,36,1) 0%, rgb(177, 177, 180)5%, rgb(128, 227, 247) 100%); */
    border-radius: 8px;
}
.CardFormulario3{
    border-radius: 8px;
}
/* Letra */
h5.h5tama18 {
    /* background-color: #E1E4EB; */
    /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
    padding: 1px;
  }
  
  @media screen and (min-width: 901px) {
    h5.h5tama18 {
      font-size: 15px;
    }
  }
  
  @media screen and (max-width: 1070px) {
    h5.h5tama18 {
      font-size: 13px;    
    }
  }

  @media screen and (max-width: 1060px) {
    h5.h5tama18 {
      font-size: 11px;    
    }
  }
/* Letra */

.Con18For{
    width: 99%;
}
.MovilidadCheck{
    /* position: absolute; */
    margin-left: -420px;
}
.FormularioCarrusel{
    width: 98.5%;
}
.FormularioCarrusel821{
    width: 100%;
}
.Con18For2{
    width: 90%;
}
.half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
}

.right {
    width: 50%;
}

.left {
    margin-right: 2%;
}

@media (max-width: 480px) {
    .half {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
}

.cf:before,
.cf:after {
    content: ' ';
    display: table;
}

.cf:after {
    clear: both;
}

.FondoDatosContacto{
    /* background-color: #22252b2c; */
    background: linear-gradient(360deg, #1122299c,#dff1f85c,#082d3b2a 115.71%);
}
.FondoDatosContactoLetra{
    color: #252729;
    filter: drop-shadow(4px 10px 6px #556888);

}
.tamaño{
    width: 80%;
}

.tamaño-total{
    width: 100%;
}

.alinearTexto{
    text-align: left;
    columns: 100%;;
    margin: auto;
    flex-flow: column;
    text-align: justify;
    justify-items: auto;
}

.alinearTitulo{
    text-align: justify ;
    justify-items: auto;
}

.btn-position{
    bottom: 0px;
}

.linea{
    width: 65%;
    border: none;
    border-top: 2px solid;
    color: #36C7D2;
}
.CCC{
justify-items: auto;
}

.ipost1{
    width: 10px;
}
.contenido, .contenido-lateral{
 
    float: left;
    box-sizing: border-box;
}
.FIzquierda4{
    background-color: #666293;
    height: 50px;
    margin-top: 100%;
    transform: scale(0.7);
    border-radius: 50px;
    filter: drop-shadow(10px 10px 15px blue);
    /* border: solid 1px #ccc;
    transform: rotate(134deg) skewX(40deg) skewY(100deg); */
    /* border-right: none;
    background: #eee linear-gradient(white, #f1f1f1 37%, #e1e1e1 57%, #f6f6f6); */
}
.FDerecha5{
    background-color: #666293;
    height: 50px;
    margin-top: 100%;
    transform: scale(0.7);
    border-radius: 50px;
    filter: drop-shadow(10px 10px 15px blue);
}

.contenido{
    width: 40%;
}

.contenido-lateral{
    width: 60%;
}

.imagen{
    width: 100%; 
    height: 280px;
}
.ALI{
    width: 100px;
}
.SNT888{
    border-radius: 10px;
  }


@media screen and (min-width: 480px) and (max-width: 780px){
    
    .contenido, .contenido-lateral, .imagen{
        width: 100%;
    }

    .imagen{
        height: 20%;
    }
}

@media screen and (max-width: 580px){
    
    .contenido, .contenido-lateral, .imagen{
        width: 100%;
    }

    .imagen{
        height: 20%;
    }
}

.prin {
    width: 100%;
    height: 100%;
}
.Mov{
    margin-top: -42px;
}
.Mov4{
    margin-top: -41px;
}
.Mov2{
    margin-top: -34px;
}
.Mov22{
    margin-top: -50px;
}
.Mov222{
    margin-top: -50px;
}
div.Mov22 {
    /* backgroundcolor: #E1E4EB; */
    filter: drop-shadow(9px 8px 10px #0c0b0b);
    padding: 1px;
  }
  
  @media screen and (min-width: 901px) {
    div.Mov22 {
      font-size: 38px;
    }
  }
  
  @media screen and (max-width: 1101px) {
    div.Mov22 {
      font-size: px;    
    }
  }
.Mov3{
    margin-top: -43px;
}
.MovCar{
    margin-top: -9px;
}
.Izquierda {
    width: 80%;
    float: left;
    margin-left: -15%;
}

.A1 {
    white-space: nowrap;
}

.Derecha1 {
    width: 20%;
    height: 25%;
    float: right;
    margin-right: -20%;
}

.Derecha2 {
    width: 25%;
    right: 25%;
    float: right;
}

.Mapa {
    opacity: 0.99;

}

.Derecha3 {
    width: 30%;
    right: 25%;
    float: right;
}

.Derecha4 {
    width: 30%;
    right: 25%;
    float: right;
}

.spn1 {
    color: yellow;
}

.LogoP {
    margin-top: 10%;
    margin-left: -1%;
}

.ImaSuc {
    width: 100%;
    height: 485px;
    opacity: 0.9;
}

.BoxSuc {
    height: 400px;
}


.flot1 {

    margin-top: 45%;
    margin-left: 25%;
    position: absolute;


}


.flot2 {

    margin-top: 22%;
    margin-left: 82%;
    position: absolute;


}

.flot3 {

    margin-top: 35%;
    margin-left: 55%;
    position: absolute;


}

.flot4 {
    margin-top: 30%;
    margin-left: 40%;
    position: absolute;
}

.flot5 {
    margin-top: 19%;
    margin-left: 27%;
    position: absolute;
}

.flot6 {
    margin-top: 17%;
    margin-left: 47%;
    position: absolute;
}

.flot7 {
    margin-top: 4%;
    margin-left: 45%;
    position: absolute;
}

.wrapper {
    height: 449px;
    overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
    width: 10px;
}

.wrapper::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 5px;
}

.chetoss:hover {
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 253) !important;
    transform: scale(1.03);
    overflow: hidden;

}


.did1 {

    margin-left: -2.5%;
}

.did2 {

    margin-left: -2.5%;
}

.Hell {
    position: absolute;
    width: 110%;
    height: 40%;
    margin-left: 2%;
    transform: translateY(-13%);
}

.PP {
    margin-left: -4%;
}

.sta {
    color: rgb(153, 150, 150);

}

.Fondo {
    position: absolute;
    z-index: -1;

}

.FIzquierda {
    margin-left: -110px;

}

.FDerecha {
    margin-right: -110px;
}

.FIzquierda1 {
    /* margin-left: -110px; */
    width: 300px;
    height: 100px;
    background-color: #666293;
    transform: scale(0.7);
    border-radius: 50px;
    filter: drop-shadow(10px 10px 15px blue);
}

.FDerecha1 {
     /* margin-right: 110px; */
    /* width: 500px; */
    height: 100px; 
    background-color: #666293;
    transform: scale(0.7);
    border-radius: 50px;
    filter: drop-shadow(10px 10px 15px blue);
}

.SombraSNT {
    filter: drop-shadow(0px 0px 3px blue);
}
.contain{
    width: 99%;
    position: relative;
}
.contain2{
    width: 95%;
    position: relative;
}
.TamaS{
    height: 45px;
}
.TamaSN {
    overflow-y: scroll;
    height: 100px;
}
.TamaSN::-webkit-scrollbar {
    width: 5px;
  }
  .TamaSN::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
    border-radius: 20px;
  }
  .TamaSN::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .TamaSN::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }
.PruS{
    position: absolute;
}
.mov1 {
    margin-right: 125%;
    z-index: 1;
    color: black;
    position: relative;
    right: 0px;
}

.mov2 {
    margin-left: 110%;
    color: black;
    position: relative;
    left: 20px;
    z-index: 1;
}
.SombraSNT {
  filter: drop-shadow(0px 0px 3px blue);
}

.parraRELE {
  font-family: 'EB Garamond', serif;
  color: #202721;
  font-size: 16pt;
  filter: drop-shadow(9px 8px 10px #0c0b0b);
}

/* .PruebaTa { */
  /* font-size: calc(1em + 1vw); */
  /* font-size: 1.7em; */
/* } */

/* Letter2 */
h2.PruebaTa {
  /* background-color: #E1E4EB; */
  filter: drop-shadow(9px 8px 10px #0c0b0b);
  padding: 1px;
}

@media screen and (min-width: 1650px) {
  h2.PruebaTa {
    font-size: 30px;
  }
}

@media screen and (max-width: 1650px) {
  h2.PruebaTa {
    font-size: 30px;
  }
}
@media screen and (max-width: 1500px) {
  h2.PruebaTa {
    font-size: 28px;
  }
}
@media screen and (max-width: 1350px) {
  h2.PruebaTa {
    font-size: 26px;
    /* font-size: 2vw; */
  }
}
@media screen and (max-width: 1200px) {
  h2.PruebaTa {
    font-size: 22px;
  }
}
@media screen and (max-width: 1050px) {
  h2.PruebaTa {
    font-size: 18px;
  }
}
@media screen and (max-width: 900px) {
  h2.PruebaTa {
    font-size: 14px;
  }
}

/* Scroll */

.ScrollRelevantes {
  overflow-y: scroll;
  height: 568px;
}
.ScrollRelevantes::-webkit-scrollbar {
  width: 7px;
}
.ScrollRelevantes::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1C1853;
  border-radius: 20px;
}
.ScrollRelevantes::-webkit-scrollbar-thumb {
  background: #0d6ae4c5;
  border-radius: 20px;
}
.ScrollRelevantes::-webkit-scrollbar-thumb:hover {
  background: #1826e983;
}
.ACD1{
    width: 15rem;
    align-content: center;
    align-items: center;
    display: inline-block;
}
.BrilloSNT{
    border-radius:6px;    
    filter: drop-shadow(1px 1px 5px rgb(70, 5, 13));
}

.BrilloSNT2{
    color: blue;
    font-size: 16pt;
    filter: drop-shadow(1px 2px 1px rgb(224, 119, 119));
}
.SomSAE{
    filter: drop-shadow(1px 1px 5px rgb(70, 5, 13));
}
.SomCOI{
    filter: drop-shadow(1px 1px 5px rgb(17, 50, 104));
}
.SomNOI{
    filter: drop-shadow(1px 1px 5px rgb(17, 50, 104));
}
.PruebaSNT4{
    /* SAE */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#f1a3a3, #fff1f3);
    border-radius: 20px;
}
.sntS{
    background: linear-gradient(#fff1f3, #fff1f3,#fff1f3);
}
.PruebaSNT3{
    /* COI */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#94e0f0, #effbfd);
    /* background-color: #effbfd; */
    border-radius: 10px;
}
.sntC{
    background: linear-gradient(#effbfd, #effbfd,#fff8f3);
}
.PruebaSNT2{
    /* NOi */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#e7c3a3, #fff8f3);
    /* background-color: #fdf6f0; */
    border-radius: 10px;
}
.sntN{
    background: linear-gradient(#fdf6f0, #fdf6f0,#fcfff2);
}
.PruebaSNT{
    /* Banco */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#dfee9c, #fcfff2);
    /* background-color: #fafcf2; */
    border-radius: 10px;
}
.sntB{
    background: linear-gradient(#fafcf2, #fafcf2,#f6f4ff);
}
.PruebaSNT5{
    /* Prod */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#bbabfc, #f6f4ff);
    /* background-color: #f7f5ff; */
    border-radius: 10px;
}
.sntP{
    background: linear-gradient(#f7f5ff, #f7f5ff,#fffcee);
}
.PruebaSNT6{
    /* Caja */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#e4d38a, #fffcee);
    /* background-color: #fffcee; */
    border-radius: 10px;
}
.sntCA{
    background: linear-gradient(#fffcee, #fffcee,#fff9fa);
}
.PruebaSNT7{
    /* Facture */
    filter: drop-shadow(1px 1px 7px rgb(17, 50, 104));
    background: linear-gradient(#f0a2af, #fff9fa);
    /* background-color: #fff9fa; */
    border-radius: 10px;
}
.sntFA{
    background: linear-gradient(#fff9fa, #fff9fa,#f7f7ff);
}
.AH2{
    color:#808188;
}
.Tit1{
    color:#EC0928;
    margin-left: 3.5%;
}
.Tit2{
    color:#00ADD9;
    margin-right: 8%;
}
.AH3{
    color: #262727;
}
.SombraSNT{
    filter: drop-shadow(0px 0px 3px blue);
}
.hrSNT {
    width: 80%;
    border-top: 1.6px dashed white!important;
    filter: drop-shadow(5px 5px 5px blue);
}

/* Scroll Areas de interes Sistemas Aspel */
.PNomina {
    overflow-y: scroll;
    height: 126px;
}
.PNomina::-webkit-scrollbar {
    width: 5px;
  }
  .PNomina::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
    border-radius: 20px;
  }
  .PNomina::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .PNomina::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }

  .CGastos {
    overflow-y: scroll;
    height: 126px;
}
.CGastos::-webkit-scrollbar {
    width: 5px;
  }
  .CGastos::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
    border-radius: 20px;
  }
  .CGastos::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .CGastos::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }

  .LinearSistemas{
    background: linear-gradient(0deg, rgba(2, 0, 36, 0.767) 0%, rgb(255, 255, 255)5%,rgba(100, 151, 218, 0.5) 100%);
  }
  .LinearSistemas2{
    background: linear-gradient(0deg, rgba(2, 0, 36, 0.767) 0%, rgb(255, 255, 255)5%,rgba(100, 155, 218, 0.856) 100% ,rgba(61, 131, 223, 0.884) 40%);
    height: 488px;
  }
  .scrollSA {
    overflow-y: scroll;
    height: 120px;
}
.scrollSA::-webkit-scrollbar {
    width: 5px;
  }
  .scrollSA::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
    border-radius: 20px;
  }
  .scrollSA::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .scrollSA::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }
  .FoSA{
    background-color:#00006915;
  }
.img-size{
	height: 450px;
	width: 700px;
	background-size: cover;
	overflow: hidden;
}
.ContaSerInte{
	width: 92%;
	text-align: center;

}
.modal-content {
   width: 80%;
  border:none;
}
.modal-body {
   padding: 0;
}

.

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009be1' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
	width: 30px;
	height: 48px;
}
.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009be1' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
	width: 30px;
	height: 48px;
}

/* letter */
button.Carru2 {
	/* background-color: #E1E4EB; */
	filter: drop-shadow(9px 8px 10px #b3aeae);
	padding: 1px;
	margin-top: -2px;
  }
  
  @media screen and (min-width: 1001px) {
	button.Carru2 {
	  font-size: 11.8pt;
	}
  }
  
  @media screen and (max-width: 1085px) {
	button.Carru2 {
	  font-size: 11px;    
	}
  }

  button.CarruDatos {
	/* background-color: #E1E4EB; */
	filter: drop-shadow(9px 8px 10px #b3aeae);
	padding: 1px;
  }
  
  @media screen and (min-width: 1001px) {
	button.CarruDatos {
	  font-size: 11.8pt;
	  margin-top: 7px;
	}
  }
  
  @media screen and (max-width: 1085px) {
	button.CarruDatos {
	  font-size: 11px;    
	}
  }

  
  button.CarruPrimer {
	/* background-color: #E1E4EB; */
	filter: drop-shadow(9px 8px 10px #b3aeae);
	padding: 1px;
  }
  
  @media screen and (min-width: 1001px) {
	button.CarruPrimer {
	  font-size: 11.8pt;
	  margin-top: 10px;
	}
  }
  
  @media screen and (max-width: 1085px) {
	button.CarruPrimer {
	  font-size: 11px;    
	}
  }

  button.CarruControl {
	/* background-color: #E1E4EB; */
	filter: drop-shadow(9px 8px 10px #b3aeae);
	padding: 1px;
  }
  
  @media screen and (min-width: 1001px) {
	button.CarruControl {
	  font-size: 11.8pt;
	  margin-top: -6px;
	}
  }
  
  @media screen and (max-width: 1085px) {
	button.CarruControl {
	  font-size: 11px;    
	}
  }
/* letter */
.flotante {
    position: absolute;
    bottom:200px;
    right:50px;
}
.flotante2 {
    position: absolute;
    bottom:200px;
    right:15px;
}

.flotin1 {
    position: absolute;
    bottom: 200px;
    right:50px;
}
.flotin2 {
    position: absolute;
    bottom:200px;
    right:15px;
}

.flotin3 {
    position: absolute;
    bottom: -12%;
    right: 10%;
}
.flotin4 {
    position: absolute;
    bottom: -5%;
    right: 10%;
}
.flotin5 {
    position: absolute;
    bottom: -4%;
    right: 10%;
}
.flotin6 {
    position: absolute;
    bottom: -10%;
    right: 10%;
}
.flotin7 {
    position: absolute;
    bottom: -25%;
    right: 10%;
}
.INIP{
    position: absolute;
    bottom: -20px;
    right: 0px;
}


.letraASPEL {
    font-family: "Nunito Sans", system-ui;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
        "wdth" 100,
        "YTLC" 500;
}

/* Scroll */

.ScrollCardClientes {
    overflow-y: scroll;
    height: 80px;
}

.ScrollCardClientes::-webkit-scrollbar {
    width: 7px;
}

.ScrollCardClientes::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1C1853;
    border-radius: 20px;
}

.ScrollCardClientes::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
}

.ScrollCardClientes::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
}
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

body {
  line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
  display:block;
}

nav ul {
  list-style:none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

/* change colours to suit your needs */

ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */

mark {
  background-color:#ff9;
  color:#000; 
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

/* change border colour to suit your needs */
hr {
  display:block;
  height:1px;
  border:0;   
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}

input, select {
  vertical-align:middle;
}
h2.HomeTeam2 {
    /* background-color: #E1E4EB; */
    /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
    padding: 1px;
    color:rgba(237, 237, 237, 0.793);
    filter: drop-shadow(1px 1px 19px #edf2fa);
}

@media screen and (min-width: 901px) {
    h2.HomeTeam2 {
        font-size: 38px;
    }
}

@media screen and (max-width: 950px) {
    h2.HomeTeam2 {
        font-size: 23px;
    }
}

.FondoVTeam{
    z-index: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    object-fit: cover;
    left: 0;
    opacity: 0.8888888;
    height: 100%;
    width: 99%.5;
    border-bottom: 80px double rgb(43, 58, 64);
    /* width: 100%; */

    /* backdrop-filter: blur(10px); */
    /* bottom: 0; */
    /* top: 0; */
    /* position: fixed;
    top: 0;
    right: 0; */

}

.imaTeam821{
    filter: drop-shadow(9px 8px 10px #0c0b0b);
    border-radius: 10px 10px 20px 20px;
}
.container-fluid5{
    width: 77%;
}
.img-size1{
	height: 600px;
	width: 100%;
	background-size: cover;
	overflow: hidden;
}
.modal-content {
   width: 700px;
  border:none;
}
.modal-body {
   padding: 0;
}
.carousel-control-prev-icon1 {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='silver' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
	width: 30px;
	height: 28px;
}
.carousel-control-next-icon1 {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='silver' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
	width: 30px;
	height: 28px;
}
.CarusellImaHei{
    height: 380px;
    background-size: cover;
    filter: drop-shadow(20px 20px 15px #556888);
    /* border-radius: 5px; */
	/* overflow: hidden; */
}
.tama821 {
    font-size: 11pt;
}
.fondoTEAMCON{
    background: linear-gradient(180deg, #dff1f892,#dff1f85c,#04192142 115.71%);
}
.lineaHorizontalSNT{
    height: 5px;
  border-color: blue;
}
.AlineadoSNT {
    position: absolute;
    /* background-color: brown; */
    margin-top: -10%;
    margin-left: -20%;
}

.Container-Contactanos{
    width:97%
}

.p-Contacta{
    /* color:thistle; */
    color:lightsteelblue;
}

html,
body {
    /* background: #f1f1f1; */
    font-family: 'Merriweather', sans-serif;
    /* padding: 1em; */
}

h1 {
    text-align: center;
    color: #a8a8a8;
}

form {
    max-width: 600px;
    text-align: center;
    margin: 20px auto;
}

input,
textarea {
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1em;
    font-family: 'Merriweather', sans-serif;
    resize: none;
    color:rgb(0, 0, 0);
}

#input-submit {
    color: rgb(255, 255, 255);
    font-size: 12pt;
    background: #4F81C5;
    cursor: pointer;
}

#input-submit:hover {
    box-shadow: 0 2px 2px 2px #07218771;
    filter: drop-shadow(4px 10px 6px #556888);
}

textarea {
    height: 258px;
}
.inputForm2{
    /* background-color: #dae9fd93; */
    background-color: #ecf0f7d5;
}
.CardFormulario{
    background:url(/static/media/Heroimagepartners.44941ddd.jpg);
    background-repeat: none;
    background-size:cover;
    /* background-color: #AEB6A7; */
    /* background: rgb(131, 30, 30); */

}

.AceptoAviso{
    opacity: 0.896;
    box-shadow: 0 2px 2px 2px #07218771;
    filter: drop-shadow(4px 10px 6px #f2f3f5);
    background: linear-gradient(to left, transparent,#ebebf0);

}
.CardFormulario23{
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0pt;
    padding-right: 0pt;
    margin-bottom: 0;
    background:url(/static/media/Barra.a28601ec.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.Nivel1P1{
    width: 100%;
    height: 493px;
}
.vidFormula821{
    position: absolute;
    left: 0;
    /* top: 0; */
    width: 100%;
    height: 493px;
    /* z-index: 10; */
    opacity: 0.4;
    visibility: visible;
    background: linear-gradient(to right, transparent,#00012D);
}
.CardFormulario18{
    background: linear-gradient(270deg, rgba(2,0,36,1) 0%,  rgb(255, 255, 255)5%,rgba(23, 113, 230, 0.5) 100%);
}
.half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
}

.right {
    width: 50%;
}

.left {
    margin-right: 2%;
}

@media (max-width: 480px) {
    .half {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
}

.cf:before,
.cf:after {
    content: ' ';
    display: table;
}

.cf:after {
    clear: both;
}
.letra-SNT{
    font-size: 1pt !important;
}
.FondoCanaco{
    background-color: #484748;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.TituloCanaco{
    font-size: 20pt;
    color: aliceblue;
}
.videoCanaco{
    z-index: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
}
.CardAjuste{
    width: 100%;
    object-fit: cover;
    /* height: 100%; */
}

.SerInCana {
    overflow-y: scroll;
    height: 450px;
  }
  .SerInCana::-webkit-scrollbar {
    width: 4px;
  }
  .SerInCana::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1C1853;
    border-radius: 20px;
  }
  .SerInCana::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .SerInCana::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }


  .SerInCanaco {
    overflow-y: scroll;
    height: 30px;
  }
  .SerInCanaco::-webkit-scrollbar {
    width: 4px;
  }
  .SerInCanaco::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1C1853;
    border-radius: 20px;
  }
  .SerInCanaco::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .SerInCanaco::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }
