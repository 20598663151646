@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.letraASPEL {
    font-family: "Nunito Sans", system-ui;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
        "wdth" 100,
        "YTLC" 500;
}

/* Scroll */

.ScrollCardClientes {
    overflow-y: scroll;
    height: 80px;
}

.ScrollCardClientes::-webkit-scrollbar {
    width: 7px;
}

.ScrollCardClientes::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1C1853;
    border-radius: 20px;
}

.ScrollCardClientes::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
}

.ScrollCardClientes::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
}