.tama821 {
    font-size: 11pt;
}
.fondoTEAMCON{
    background: linear-gradient(180deg, #dff1f892,#dff1f85c,#04192142 115.71%);
}
.lineaHorizontalSNT{
    height: 5px;
  border-color: blue;
}
.AlineadoSNT {
    position: absolute;
    /* background-color: brown; */
    margin-top: -10%;
    margin-left: -20%;
}

.Container-Contactanos{
    width:97%
}

.p-Contacta{
    /* color:thistle; */
    color:lightsteelblue;
}
