.IDE1 {
    filter: drop-shadow(8px 0px 5px rgb(115, 115, 214));
    width: 100%;
    height: 88%;
    position: absolute;
}

.ConIn1 {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: left;
    justify-content: left;
}

.ima1p-1 {
    float: right;
}

.ConServicios {
    width: 90%;
}

.MS18 {
    /* style={{ backgroundColor: '#eff3fb' }} */
    border-radius: 10px;
    background-color: #eceef1;
    /* background-color: #c5cbd5; */
}

.AjuSNT {
    filter: drop-shadow(5px 0px 5px rgb(115, 115, 214));
    border-radius: 10px 0px 0px 10px;
}

.AjuSNT2 {
    filter: drop-shadow(8px 0px 5px rgb(90, 90, 189));
    border-radius: 0px 10px 10px 0px;
}

.IDE12 {
    width: 70%;
    border-radius: 200px 200px;
}

.IDE12SNT {
    width: 95%;
    border-radius: 100px;
    opacity: 0.9;
    filter: drop-shadow(5px 0px 5px rgb(186, 186, 190));
}

.IDE18SNT {
    width: 95%;
    border-radius: 100px;
    opacity: 0.9;
    filter: drop-shadow(5px 0px 5px rgb(186, 186, 190));
}

.IDE2 {
    width: 30%;
    border-radius: 200px 200px;
}

.IDE11 {
    width: 90%;
}

.parrafo {
    color: #696A6F;
    font-size: 13pt;
}

.DDD {
    position: absolute;
    margin-top: 17%;
    margin-left: 3%;
}

.pruebaas {
    margin-right: auto;
}

.par1 {
    font-size: 14pt;
}

.TpLetraSNT {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font: oblique 150% cursive;
    color: rgb(30, 30, 56);
    filter: drop-shadow(1px 1px 10px rgb(115, 115, 214));

}

.BRSNT {
    border-radius: 10px;
}

.BRSNT2 {
    border-radius: 20px;
}

.SombrillaSNT {
    filter: drop-shadow(1px 1px 10px rgb(18, 18, 50));
}

.AcomodoSNT {
    border-radius: 10px;
    filter: drop-shadow(1px 1px 10px rgb(18, 18, 50));
}

/* Fondo Certificados */
.Acomodo2SNT {
    position: absolute;
    opacity: 0.9;
    border-radius: 10px;
    filter: drop-shadow(1px 1px 10px rgb(184, 184, 203));
}

.SomSNT {
    filter: drop-shadow(1px 1px 10px rgb(184, 184, 203));
}

.Acomodo21SNT {
    position: relative;
    margin-top: -0.2%;
    margin-left: -0%;
    opacity: 0.9;

}

.Acomodo22SNT {
    position: relative;
    margin-top: 0%;
    margin-left: 0%;
    opacity: 0.6;
}

.Acomodo23SNT {
    position: relative;
    margin-top: 0%;
    margin-left: 0%;
    opacity: 0.8;
}

.Acomodo24SNT {
    position: relative;
    margin-top: -72%;
    margin-left: 0%;
    opacity: 0.8;
}

.Acomodo25SNT {
    position: relative;
    margin-top: -72%;
    margin-left: 0%;
    opacity: 0.8;
}

.AcomodoSNT1 {
    position: relative;
}

h1.InfraJumbo {
    /* background-color: #E1E4EB; */
    /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
    padding: 1px;
}

@media screen and (min-width: 901px) {
    h1.InfraJumbo {
        font-size: 38px;
    }
}

@media screen and (max-width: 950px) {
    h1.InfraJumbo {
        font-size: 23px;
    }
}

.Container-Servicios {
    width: 99%;
    /* padding-top: 65px; */
}

.SnippDiv {
    display: flex;
    justify-content: space-between;
}

.PdVServicios {
    width: 100%;
    position: relative;
    /* para poder posicionar el texto de forma absoluta */
    display: inline-block;
    /* para que solo ocupe el ancho de la imagen */
}

.PdVServiciosTexto {
    position: absolute;
    /* posición absolute con respecto al padre */
    top: 0;
    /* posicionada en la esquina inferior derecha */
    left: 1rem;
}

.CursoSAspel {
    background-color: #aacbf48c;
}

.fondoSistemaAs {
    /* background-color: #00006a13; */
    background-color: #2ba4f546;
}

.v-line {
    border-left: thin solid black;
    height: 91%;
    right: 0%;
    position: absolute;
}

.AspelBANCOIMA {
    color: #c1e96c;
}

.CotizaCurso {
    border-radius: 10px;
    filter: drop-shadow(2px 0px 4px rgb(5, 14, 82));
}

.InterLineCurso{
    line-height: 1.3;
}

.VideoFormatos{
    width: 100%;
    height: auto;
    border-radius: 10px;
    filter: drop-shadow(2px 0px 4px rgb(5, 14, 82));
}


/*  */
p.PdVServiciosTexto {
    /* background-color: #E1E4EB; */
    /* filter: drop-shadow(9px 8px 10px #0c0b0b); */
    padding: 1px;
}

@media screen and (min-width: 901px) {
    p.PdVServiciosTexto {
        font-size: 40px;
    }
}

@media screen and (max-width: 950px) {
    p.PdVServiciosTexto {
        font-size: 23px;
    }
}

/* Scroll X */
.CursoDisponibleA {
    overflow-y: scroll;
    height: 65px;
}

.SerIn::-webkit-scrollbar {
    width: 5px;
}

.SerIn::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1C1853;
    border-radius: 20px;
}

.SerIn::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
}

.SerIn::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
}