.prin {
    width: 100%;
    height: 100%;
}
.Mov{
    margin-top: -42px;
}
.Mov4{
    margin-top: -41px;
}
.Mov2{
    margin-top: -34px;
}
.Mov22{
    margin-top: -50px;
}
.Mov222{
    margin-top: -50px;
}
div.Mov22 {
    /* backgroundcolor: #E1E4EB; */
    filter: drop-shadow(9px 8px 10px #0c0b0b);
    padding: 1px;
  }
  
  @media screen and (min-width: 901px) {
    div.Mov22 {
      font-size: 38px;
    }
  }
  
  @media screen and (max-width: 1101px) {
    div.Mov22 {
      font-size: px;    
    }
  }
.Mov3{
    margin-top: -43px;
}
.MovCar{
    margin-top: -9px;
}
.Izquierda {
    width: 80%;
    float: left;
    margin-left: -15%;
}

.A1 {
    white-space: nowrap;
}

.Derecha1 {
    width: 20%;
    height: 25%;
    float: right;
    margin-right: -20%;
}

.Derecha2 {
    width: 25%;
    right: 25%;
    float: right;
}

.Mapa {
    opacity: 0.99;

}

.Derecha3 {
    width: 30%;
    right: 25%;
    float: right;
}

.Derecha4 {
    width: 30%;
    right: 25%;
    float: right;
}

.spn1 {
    color: yellow;
}

.LogoP {
    margin-top: 10%;
    margin-left: -1%;
}

.ImaSuc {
    width: 100%;
    height: 485px;
    opacity: 0.9;
}

.BoxSuc {
    height: 400px;
}


.flot1 {

    margin-top: 45%;
    margin-left: 25%;
    position: absolute;


}


.flot2 {

    margin-top: 22%;
    margin-left: 82%;
    position: absolute;


}

.flot3 {

    margin-top: 35%;
    margin-left: 55%;
    position: absolute;


}

.flot4 {
    margin-top: 30%;
    margin-left: 40%;
    position: absolute;
}

.flot5 {
    margin-top: 19%;
    margin-left: 27%;
    position: absolute;
}

.flot6 {
    margin-top: 17%;
    margin-left: 47%;
    position: absolute;
}

.flot7 {
    margin-top: 4%;
    margin-left: 45%;
    position: absolute;
}

.wrapper {
    height: 449px;
    overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
    width: 10px;
}

.wrapper::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 5px;
}

.chetoss:hover {
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 253) !important;
    transform: scale(1.03);
    overflow: hidden;

}


.did1 {

    margin-left: -2.5%;
}

.did2 {

    margin-left: -2.5%;
}

.Hell {
    position: absolute;
    width: 110%;
    height: 40%;
    margin-left: 2%;
    transform: translateY(-13%);
}

.PP {
    margin-left: -4%;
}

.sta {
    color: rgb(153, 150, 150);

}

.Fondo {
    position: absolute;
    z-index: -1;

}

.FIzquierda {
    margin-left: -110px;

}

.FDerecha {
    margin-right: -110px;
}

.FIzquierda1 {
    /* margin-left: -110px; */
    width: 300px;
    height: 100px;
    background-color: #666293;
    transform: scale(0.7);
    border-radius: 50px;
    filter: drop-shadow(10px 10px 15px blue);
}

.FDerecha1 {
     /* margin-right: 110px; */
    /* width: 500px; */
    height: 100px; 
    background-color: #666293;
    transform: scale(0.7);
    border-radius: 50px;
    filter: drop-shadow(10px 10px 15px blue);
}

.SombraSNT {
    filter: drop-shadow(0px 0px 3px blue);
}
.contain{
    width: 99%;
    position: relative;
}
.contain2{
    width: 95%;
    position: relative;
}
.TamaS{
    height: 45px;
}
.TamaSN {
    overflow-y: scroll;
    height: 100px;
}
.TamaSN::-webkit-scrollbar {
    width: 5px;
  }
  .TamaSN::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(235, 242, 247, 0.548);
    border-radius: 20px;
  }
  .TamaSN::-webkit-scrollbar-thumb {
    background: #0d6ae4c5;
    border-radius: 20px;
  }
  .TamaSN::-webkit-scrollbar-thumb:hover {
    background: #1826e983;
  }
.PruS{
    position: absolute;
}
.mov1 {
    margin-right: 125%;
    z-index: 1;
    color: black;
    position: relative;
    right: 0px;
}

.mov2 {
    margin-left: 110%;
    color: black;
    position: relative;
    left: 20px;
    z-index: 1;
}