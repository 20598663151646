.let1 {
     font-size: 8pt;
}

.containerSNT {
     width: 90%;
}

.StyleSNT {
     /* background: linear-gradient(#eff3fb,#dbdfe7,rgb(16, 70, 80) 115.71%); */
     background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
     border-radius: 10px;
     /* background: ; */
}

.StyleSNT2 {
     background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
     border-radius: 10px 0px 0px 10px;
     height: 125px;
}
.StyleSNT3 {
     background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
     border-radius: 0px 0px 0px 0px;
     height: 125px;
}
.footLog{
     max-width: 100%;
     max-height: 100%;
}
.StyleSNT4 {
     background: linear-gradient(#eff3fb, #dbdfe7, rgb(132, 144, 148) 115.71%);
     border-radius: 0px 10px 10px 0px;
     height: 125px;
}


.fotcol {
     background-color: #C0C8DE;
     z-index: -1;
}

.imgSNT {
     border-radius: 15px;
}

.fondFoote {
     background-color: transparent;
     z-index: 0;
}